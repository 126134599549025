<template>
  <div>
   <p v-if="!address">
     Awaiting Keplr...
   </p><p v-else>
     Account is signed in 
   </p>
  </div>
</template>

<style scoped>
.container {
  margin-bottom: 1.5rem;
}
.card {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 1rem;

  padding: 0.25rem 0.75rem;
  overflow-x: hidden;
}

.card__row {
  display: flex;
  align-items: center;
}

.card__desc {
  word-break: break-all;
}

.coin__amount {
  text-transform: uppercase;

  letter-spacing: 0.02em;
  font-weight: 600;
}
.coin__amount:after {
  content: ",";
  margin-right: 0.25em;
}
.coin__amount:last-child:after {
  content: "";
  margin-right: initial;
}
</style>

<script>
import * as bip39 from "bip39";
import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing/";
import { makeCosmoshubPath } from "@cosmjs/launchpad";
import { SigningStargateClient } from "@cosmjs/stargate";
import axios from "axios";
import { auth } from "./firebase/db.js";

export default {
  data() {
    return {
      password: "",
      error: false,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    address() {
      const client = this.$store.getters.account;
      //console.log(client)
      return client && client.address;
    },
 
 

    balances() {
      //console.log(this.$store.state.bankBalances)
      return this.$store.getters.bankBalances;
    },
    keplrWallet() {
      return localStorage.getItem("keplr");
    },

    
  },
  mounted(){
      let addr = this.address
      if (!addr){
        this.trySignIn()
      }
    },

  methods: {
    async trySignIn() {
     /* if (keplrWallet){
          await this.$store.dispatch("bankBalancesGet");

          this.$store.commit("set", { key: "keplr", value:keplrWallet });
          this.$store.commit("set", {
            key: "account",
            value: keplrWallet,
          });


          this.$store.dispatch("setCodeHash");
          this.$store.dispatch("setMasterKey");
      }*/
      let addressPrefix = "trust";
      // sometimes the page loads quicker the keplr is available
      // so we try again for a couple of times but give up at somepoint
      if (!window.keplr && trys < 3) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        // dispatch('init', trys + 1)
      }
      if (window.keplr && window.keplr.experimentalSuggestChain) {
        //  const block = await dispatch('data/getBlock', undefined, { root: true })
        try {
          // Keplr v0.6.4 introduces an experimental feature that supports the feature to suggests the chain from a webpage.
          // cosmoshub-3 is integrated to Keplr so the code should return without errors.
          // The code below is not needed for cosmoshub-3, but may be helpful if you’re adding a custom chain.
          // If the user approves, the chain will be added to the user's Keplr extension.
          // If the user rejects it or the suggested chain information doesn't include the required fields, it will throw an error.
          // If the same chain id is already registered, it will resolve and not require the user interactions.
          await window.keplr.experimentalSuggestChain({
            // Chain-id of the Cosmos SDK chain.
            chainId: "trst_chain_1",
            // The name of the chain to be displayed to the user.
            chainName: "Trustless Hub",
            // RPC endpoint of the chain.
            rpc: process.env.VUE_APP_RPC,
            // REST endpoint of the chain.
            rest: process.env.VUE_APP_API,
            // Staking coin information
            stakeCurrency: "utrst",
            // (Optional) If you have a wallet webpage used to stake the coin then provide the url to the website in `walletUrlForStaking`.
            // The 'stake' button in Keplr extension will link to the webpage.
            // walletUrlForStaking: "",
            // The BIP44 path.
            bip44: {
              // You can only set the coin type of BIP44.
              // 'Purpose' is fixed to 44.
              coinType: 118,
            },
            // Bech32 configuration to show the address to user.
            // This field is the interface of
            // {
            //   bech32PrefixAccAddr: string;
            //   bech32PrefixAccPub: string;
            //   bech32PrefixValAddr: string;
            //   bech32PrefixValPub: string;
            //   bech32PrefixConsAddr: string;
            //   bech32PrefixConsPub: string;
            // }

            bech32Config: {
              bech32PrefixAccAddr: addressPrefix,
              bech32PrefixAccPub: addressPrefix + "pub",
              bech32PrefixValAddr: addressPrefix + "valoper",
              bech32PrefixValPub: addressPrefix + "valoperpub",
              bech32PrefixConsAddr: addressPrefix + "valcons",
              bech32PrefixConsPub: addressPrefix + "valconspub",
            },
            // List of all coin/tokens used in this chain.
            currencies: ["utrst"],
            // List of coin/tokens used as a fee token in this chain.
            feeCurrencies: ["utrst"],

            // (Optional) The number of the coin type.
            // This field is only used to fetch the address from ENS.
            // Ideally, it is recommended to be the same with BIP44 path's coin type.
            // However, some early chains may choose to use the Cosmos Hub BIP44 path of '118'.
            // So, this is separated to support such chains.
            coinType: 118,
            // (Optional) This is used to set the fee of the transaction.
            // If this field is not provided, Keplr extension will set the default gas price as (low: 0.01, average: 0.025, high: 0.04).
            // Currently, Keplr doesn't support dynamic calculation of the gas prices based on on-chain data.
            // Make sure that the gas prices are higher than the minimum gas prices accepted by chain validators and RPC/REST endpoint.
            // gasPriceStep: {
            //     low: 0.01,
            //     average: 0.025,
            //     high: 0.04
            // }
          });
          await window.keplr.enable("trst_chain_1");

          const offlineSigner = await window.getOfflineSignerAuto("trst_chain_1");
    console.log(offlineSigner)
          // You can get the address/public keys by `getAccounts` method.
          // It can return the array of address/public key.
          // But, currently, Keplr extension manages only one address/public key pair.
          // XXX: This line is needed to set the sender address for SigningCosmosClient.
          const accounts = await offlineSigner.getAccounts();
          console.log(accounts[0].address);
          
          this.$store.commit("set", { key: "keplr", value: accounts[0] });
          this.$store.commit("set", {
            key: "account",
            value: accounts[0],
          });

          await this.$store.dispatch("bankBalancesGet");

         // this.initConfig();
          localStorage.setItem("keplr", accounts[0]);

          this.$store.dispatch("setCodeHash");
          this.$store.dispatch("setMasterKey");
        } catch (error) {
          alert(error.message);
          return;
        }
      }
    },
  },
};
</script>